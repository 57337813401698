<template>
    <main class="main-page"  id="">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container-fluid">
                    <div class="grid justify-content-between align-items-center">
                        <div  class="col " >
                            <div class=" text-2xl text-primary font-bold" >
                                Laporan Penjualan
                            </div>
                        </div>
                        <div  class="col-12 md:col-3 " >
                        </div>
                        <div  class="col-12 md:col-5 lg:col-4 " >
                            <span class="p-input-icon-left w-full">
                            <i class="pi pi-search" />
                            <InputText  placeholder="Search" class="w-full" :value="searchText" @input="debounce(() => { searchText = $event.target.value })"  />
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <section class="page-section " >
            <div class="container-fluid">
                <div class="grid ">
                    <div  class="col comp-grid" >
                        <div class="flex align-items-center justify-content-around">
                            <div v-if="searchText" :class="filterTagClass">
                                Search
                                <Chip class="font-medium px-2 py-1" removable @remove="clearSearch()">{{ searchText }}</Chip>
                            </div>
                        </div>
                        <div >
                            <template v-if="showBreadcrumbs && $route.query.tag && !isSubPage">
                                <Breadcrumb :home="{icon: 'pi pi-home', to: '/keranjang'}" :model="pageBreadCrumb" />
                            </template>
                            <!-- page records template -->
                            <div class="page-records"  >
                                <DataTable :lazy="true"   :loading="loading"     :value="records" dataKey="id" @sort="onSort($event)" class="" :showGridlines="false" :rowHover="true" responsiveLayout="stack">
                                    <Column  field="no_invoice" header="No Invoice" >
                                        <template #body="{data}">
                                            {{ data.no_invoice }}
                                        </template>
                                    </Column>
                                    <Column  field="kode_produk" header="Kode Produk" >
                                        <template #body="{data}">
                                            {{ data.kode_produk }}
                                        </template>
                                    </Column>
                                    <Column  field="nama_produk" header="Nama Produk" >
                                        <template #body="{data}">
                                            {{ data.nama_produk }}
                                        </template>
                                    </Column>
                                    <Column  field="harga" header="Harga" >
                                        <template #body="{data}">
                                            {{$utils.currency( data.harga , 'IDR', )}}
                                        </template>
                                    </Column>
                                    <Column  field="qty" header="Qty" >
                                        <template #body="{data}">
                                            {{ data.qty }}
                                        </template>
                                    </Column>
                                    <Column  field="jenis_pengiriman" header="Jenis Pengiriman" >
                                        <template #body="{data}">
                                            {{ data.jenis_pengiriman }}
                                        </template>
                                    </Column>
                                    <Column  field="biaya_pengiriman" header="Biaya Pengiriman" >
                                        <template #body="{data}">
                                            {{ data.biaya_pengiriman }}
                                        </template>
                                    </Column>
                                    <Column  field="total" header="Total" >
                                        <template #body="{data}">
                                            {{$utils.currency( data.total , 'IDR', )}}
                                        </template>
                                    </Column>
                                    <Column  field="status_pembayaran" header="Status Pembayaran" >
                                        <template #body="{data}">
                                            {{ data.status_pembayaran }}
                                        </template>
                                    </Column>
                                    <Column  field="date_created" header="Tanggal Transaksi" >
                                        <template #body="{data}">
                                            {{$utils.humanDate( data.date_created )}}
                                        </template>
                                    </Column>
                                    <template #footer>
                                        <div class="flex justify-content-around font-bold">
                                            <div>Total Penjualan: <Chip :label="totalTotal" /></div>
                                        </div>
                                    </template>
                                </DataTable>
                            </div>
                            <!-- page loading indicator -->
                            <template v-if="loading">
                            </template>
                            <!-- end of page loading indicator-->
                            <!-- Empty record -->
                            <template v-if="pageReady && !records.length">
                                <div class="p-3 my-3 text-500 text-lg font-medium text-center">
                                    Data Tidak Ditemukan
                                </div>
                            </template>
                            <!-- end of empty record-->
                            <!-- pagination component-->
                            <template v-if="showFooter && pageReady">
                                <div class="flex justify-content-between align-items-center">
                                    <div class="flex justify-content-center flex-grow-0">
                                        <div class="m-2" v-if="exportButton && records.length">
                                            <Button @click="(event)=> $refs.exportMenu.toggle(event)" label="Export"  title="Export" icon="pi pi-print" />
                                            <Menu ref="exportMenu" popup :model="pageExportFormats" />
                                        </div>
                                    </div>
                                    <div v-if="paginate && totalPages > 1" class="flex-grow-1">
                                        <Paginator class="border-none bg-transparent py-3" :first="recordsPosition - 1" @page="(event)=>{pagination.page = event.page + 1}" :rows="pagination.limit" :totalRecords="totalRecords">
                                            <template #start>
                                                <span class="text-sm text-gray-500 px-2">
                                                Records <b>{{ recordsPosition }} of {{ totalRecords }}</b>
                                                </span>
                                            </template>
                                            <template #end>
                                            </template>
                                        </Paginator>
                                    </div>
                                </div>
                            </template>
                            <!-- end of pagination component-->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>
<script setup>
	import {  computed,  toRefs, onMounted } from 'vue';
	import { usePageStore } from 'src/store/page';
	import { utils } from 'src/utils';
	import { useApp } from 'src/composables/app.js';
	import { useListPage } from 'src/composables/listpage.js';
	
	const props = defineProps({
		primaryKey : {
			type : String,
			default : 'id',
		},
		pageStoreKey: {
			type: String,
			default: 'KERANJANG-LAPORAN_PENJUALAN',
		},
		pageName: {
			type: String,
			default : 'keranjang',
		},
		routeName: {
			type: String,
			default: 'keranjanglaporan_penjualan',
		},
		apiPath: {
			type: String,
			default: 'keranjang/laporan_penjualan',
		},
		paginate: {
			type: Boolean,
			default: true,
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		showBreadcrumbs: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		importButton: {
			type: Boolean,
			default: false,
		},
		multiCheckbox: {
			type: Boolean,
			default: false,
		},
		emptyRecordMsg: {
			type: String,
			default: "Data Tidak Ditemukan",
		},
		titleBeforeDelete: {
			type: String,
			default: "Delete record",
		},
		msgBeforeDelete: {
			type: String,
			default: "Yakin ingin menghapus data ini?",
		},
		msgAfterDelete: {
			type: String,
			default: "Data berhasil dihapus",
		},
		page: {
			type: Number,
			default: 1,
		},
		limit: {
			type: Number,
			default: 10,
		},
		mergeRecords: { // for infinite loading
			type: Boolean,
			default: false,
		},
		search: {
			type: String,
			default: '',
		},
		fieldName: null,
		fieldValue: null,
		sortBy: {
			type: String,
			default: '',
		},
		sortType: {
			type: String,
			default: 'desc', //desc or asc
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
		filterTagClass: {
			type: String,
			default: 'surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-center',
		}
	});
	
	const app = useApp();
	
	const defaultStoreState = {
		filters: {
		},
		pagination: {
			page: props.page,
			limit: props.limit,
			sortBy: props.sortBy,
			sortType: props.sortType
		},
		searchText: props.search,
		primaryKey: props.primaryKey
	}
	const store = usePageStore(props.pageStoreKey,  defaultStoreState);
	
	// page hooks where logics resides
	const page = useListPage({ store, props });
	
	const {records, filters,  totalRecords,  selectedItems,  pagination,} = toRefs(store.state);
	const { pageReady, loading, searchText, } = toRefs(page.state);
	
	const {  pageBreadCrumb,   totalPages, recordsPosition, } = page.computedProps;
	
	const { load,    exportPage, debounce, clearSearch, onSort,  deleteItem,       } = page.methods;
	
	const pageExportFormats =  [
		{
			label: 'Pdf',
			icon: 'pi pi-file-pdf text-pink-500',
			command: () => { exportPage('pdf') }
		},
		{
			label: 'Excel',
			icon: 'pi pi-file-excel text-green-500',
			command: () => { exportPage('excel') }
		}
	];
	function getActionMenuModel(data){
		return []
	}
	const totalTotal = computed(() => records.value.sum("total"));
	
	onMounted(()=>{ 
		const pageTitle = "Laporan Penjualan";
		app.setPageTitle(props.routeName, pageTitle);
	});
</script>
<style scoped>
</style>
